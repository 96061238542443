<template>
  <div>
    <b-modal :visible="modalCreatePickup" :title="$t('dashboard.pickups.createPickup')" content-class="card form-rounded-inputs card-ecart" centered
      header-class="card-header" @hidden="resetNewPickup()" cancel-variant="danger" size="md" hide-header-close>
      <v-observer tag="form" @submit.prevent="fnAddNewPayment()" ref="pickupForm" class=""> 
        <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.storeName')">
          <div class="form-group">
            <label for="" v-text="$t('general.form.storeName')"></label>
            <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
              <select class="custom-select " :class=" { 'is-invalid ':errors.length > 0 } "
                :disabled="pickupOrderInformation.id != null" @change="fnApiGetStoreAddresses(); fnReset() "
                v-model="newPickup.storeId">
                <option :value="null" v-text=" $t('general.form.select') ">
                </option>
                <option :value="store.id" v-for="  ( store, index ) in storesList  " :key="index"
                  v-text="store.name ? store.name : store.store_id">
                </option>
              </select>
              <div class="input-group-append">
                <label class="input-group-text"> <i class="fa fa-store"></i> </label>
              </div>
            </div>
            <div class="invalid-feedback" v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </div>
        </v-validation>

        <v-validation :rules=" (pickupOrderInformation.id == null) ? 'required' : '' " v-slot="{ errors }"
          :name="$t('general.form.address')" v-show="newPickup.storeId && (pickupOrderInformation.id == null) ">

          <div class="form-group">
            <label for="" v-text="$t('general.form.address')"></label>
            <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
              <select class="custom-select " :class=" { 'is-invalid ':errors.length > 0 } " @change="fnChangeAddress"
                v-model="newPickup.addressId">
                <option :value="null" v-text=" $t('general.form.select') ">
                </option>
                <option :value="address.id" v-for="  ( address, index ) in addressesList  " :key="index"
                  v-text="address.address_name ? address.address_name : address.address1">
                </option>
              </select>
              <div class="input-group-append">
                <label class="input-group-text"> <i class="fa fa-map-marked-alt"></i> </label>
              </div>
            </div>
            <div class="invalid-feedback" v-if="errors.length > 0">
              {{ errors[0] }}
            </div>
          </div>

        </v-validation>

        <div class=" d-flex flex-column justify-content-center mb-2" v-if="pickupOrderInformation.id">
          <p class="m-0">
            <span
              v-text=" $t('tables.name') + ': ' + pickupOrderInformation.shipping_items[0].origin.first_name + ' ' + pickupOrderInformation.shipping_items[0].origin.last_name "></span>
          </p>
          <p class="m-0">
            <span v-text=" $t('tables.phone') + ': ' + pickupOrderInformation.shipping_items[0].origin.phone "></span>
          </p>
          <p class="m-0 ">
            <span v-text=" $t('tables.address') + ': ' "></span>
            <span v-text="fnFormatAddress( pickupOrderInformation.shipping_items[0].origin )"></span>
          </p>
        </div>

        <div v-show="newPickup.addressId || (pickupOrderInformation.id != null) ">
          <hr>
          <div class="row">
            <div class="col-12 col-md-6">
              <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.carrier')">
                <!-- truck -->
                <div class="form-group">
                  <label for="" v-text="$t('general.form.carrier')"></label>
                  <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                    <select class="custom-select text-uppercase " :class=" { 'is-invalid ':errors.length > 0 } "
                      @change="fnChangeCarrier" v-model="newPickup.shipment.carrier"
                      :disabled="pickupOrderInformation.id">
                      <option :value="null" v-text=" $t('general.form.select') ">
                      </option>
                      <option :value="carrier.name" v-for="  ( carrier, index ) in carriersFilter  " :key="index"
                        v-text="carrier.name">
                      </option>
                    </select>
                    <div class="input-group-append">
                      <label class="input-group-text "> <i class="fa fa-truck "></i> </label>
                    </div>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>

            </div>
            <div class="col-12 col-md-6">
              <div v-show="newPickup.shipment.carrier">
                <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.pickupDate') ">
                  <div class="form-group ">
                    <label for="" v-text="$t('general.form.pickupDate')"> </label>
                    <v-date-picker :output-format='CONST.DATE_FORMATS.YYYY_MM_DD' class="date-picker-container"
                      :formatted="CONST.DATE_FORMATS.YYYY_MM_DD" @input="fnGenerateHours()" right
                      v-model="newPickup.shipment.pickup.date" :min-date=" CONST.TODAY " no-header only-date dark
                      no-button auto-close>
                      <input type="text" class="form-control" v-model=" pickupDateString ">
                    </v-date-picker>
                    <div class="invalid-feedback" v-if="errors.length > 0">
                      {{ errors[0] }}
                    </div>
                  </div>
                </v-validation>
              </div>
            </div>
          </div>

          <div v-show="availableStartHours.length > 0 && newPickup.shipment.carrier">

            <!-- DATES -->
            <div class="row">
              <div class="col-6">
                <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.from')">
                  <div class="form-group">
                    <label for="" v-text="$t('general.form.from')"></label>
                    <div class="input-group " :class=" { 'is-invalid':errors.length > 0 } ">
                      <select class="custom-select " @change="fnChangeStartHour" disabled
                        :class=" { 'is-invalid':errors.length > 0 } " v-model="newPickup.shipment.pickup.time_from">
                        <option :value="null" v-text=" $t('general.form.select') ">
                        </option>
                        <option :value="hour.value" v-for=" ( hour, index ) in availableStartHours " :key="index"
                          v-text="hour.label">
                        </option>
                      </select>
                      <div class="input-group-append">
                        <label class="input-group-text"> <i class="fa fa-clock"></i> </label>
                      </div>
                    </div>
                    <div class="invalid-feedback" v-if="errors.length > 0">
                      {{ errors[0] }}
                    </div>

                  </div>
                </v-validation>
              </div>
              <div class="col-6">
                <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.to')">
                  <div class="form-group">
                    <label for="" v-text="$t('general.form.to')"></label>
                    <div class="input-group " :class=" { 'is-invalid':errors.length > 0 } ">
                      <select class="custom-select" disabled v-model="newPickup.shipment.pickup.time_to"
                        :class=" { 'is-invalid':errors.length > 0 } ">
                        <option :value="null" v-text=" $t('general.form.select') ">
                        </option>
                        <option :value="hour.value" v-for=" ( hour, index ) in availableEndHours " :key="index"
                          v-text="hour.label">
                        </option>
                      </select>
                      <div class="input-group-append">
                        <label class="input-group-text"> <i class="fa fa-clock"></i> </label>
                      </div>
                    </div>
                    <div class="invalid-feedback" v-if="errors.length > 0">
                      {{ errors[0] }}
                    </div>
                  </div>
                </v-validation>
              </div>
              <div class="col-12">
                <hr>
              </div>
            </div>

            <!-- Packages -->
            <div class="row">
              <div class="col-6">
                <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.totalPackages')">
                  <div class="form-group">
                    <label v-text="$t('general.form.totalPackages')"></label>
                    <div class="input-group">
                      <input type="number" min="1" step="1" class="form-control"
                        v-model=" newPickup.shipment.pickup.total_packages "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text "> <i class="fa fa-box"></i> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

              <div class="col-6">
                <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.totalWeight')">
                  <div class="form-group">
                    <label v-text="$t('general.form.totalWeight')"></label>
                    <div class="input-group">
                      <input type="number" min="1" step="1" class="form-control"
                        v-model=" newPickup.shipment.pickup.total_weight "
                        :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text "> <strong v-text=" CONST.UNITS.WEIGHT "> </strong>
                        </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

              <div class="col-12">
                <v-validation rules="required" v-slot="{ errors }" :name="$t('general.form.instructions')">
                  <div class="form-group">
                    <label for="" v-text="$t('general.form.instructions')"> </label>
                    <textarea name="" id="" cols="30" rows="3" class="form-control"
                      v-model=" newPickup.shipment.pickup.instructions "
                      :class=" { 'is-invalid':errors.length > 0 } "></textarea>
                    <div class="invalid-feedback" v-if="errors.length > 0">
                      {{ errors[0] }}
                    </div>
                  </div>
                </v-validation>
              </div>
            </div>

            <p v-if="pickupsErrors.newPickup " class="mb-0 text-center bg-warning p-2 rounded">
              <i class="fa fa-exclamation-triangle mr-1 "></i> <span
                v-text="$t('dashboard.pickups.errors.' + pickupsErrors.newPickup )"></span>
            </p>
          </div>
          <div v-show="availableStartHours.length == 0 && newPickup.shipment.carrier">
            <p class="mb-0 text-center bg-warning p-2 rounded">
              <i class="fa fa-exclamation-triangle mr-1 "></i>
              <span v-text="$t('dashboard.pickups.pickupNotAvailable')"></span>
            </p>
          </div>
        </div>

      </v-observer>
      <template v-slot:modal-footer>
        <div class="d-flex w-100">
          <div class="ml-auto">
            <b-button variant="none" class=" mr-2" v-text=" $t('general.button.close') "
              @click="resetNewPickup">
            </b-button>
            <b-button variant="success" class="" @click=" fnValidateData() " :disabled="loading.newPickup">
              <span v-text="$t('general.button.create')" v-if=" !loading.newPickup "></span>
              <i class="fa fa-spinner fa-spin " v-else></i>
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  export default {
    data() {
      return ({
        countriesList: [],
        statlesList: [],
        provincesList: [],
        pickupDateString: null,
        // Hours
        carrierPickup: {},
        availableStartHours: [],
        availableEndHours: [],
        limits: {},
        carriersFilter: [],
      })
    },
    computed: {
      // ...mapGetters('pickups', ['getStoreAddresses']),
      ...mapState('pickups', ['loading', 'modalCreatePickup', 'newPickup', 'storesList', 'addressesList',
        'carriersList', 'pickupsErrors', 'pickupOrderInformation', 'carriers'
      ]),
    },
    watch: {
      newPickup: {
        deep: true,
        handler() {
          if (this.newPickup.origin.address.country.code && (this.carriers.length == 0)) {
            this.fnApiGetCarriers(this.newPickup.origin.address.country.code);
          }
        }
      },
      carriers() {
        this.carriersFilter = [];
        for (let carrier of this.carriers) {
          this.carriersFilter.push(this.carriersList.find(tempCarrier => tempCarrier.name == carrier.name));
        }
      }
    },
    methods: {
      ...mapMutations('pickups', ['setModalPickup', 'resetNewPickup', 'resetState']),
      ...mapActions('pickups', ['fnApiGetStoreAddresses', 'fnApiCreatePickup', 'fnApiGetCarriers']),

      async fnValidateData() {
        if (await this.$refs['pickupForm'].validate()) {
          this.fnApiCreatePickup();
        }
      },

      fnReset() {
        this.newPickup.addressId = null;
      },

      async fnGetStates(code, countryChanged) {
        this.statlesList = [];
        this.statlesList = await this.fnApiGetStates(code);
        if (countryChanged) {
          this.storeAddress.state.code = null;
          this.storeAddress.state.name = null;
          if (this.statlesList.length > 0) {
            this.storeAddress.state.code = this.statlesList[0].code_2_digits;
            this.fnGetProvinces(this.storeAddress.state.code, true);
          }
        }
      },

      fnChangeAddress() {
        if (this.newPickup.addressId) {
          let tempAddress = this.addressesList.find(address => address.id == this.newPickup.addressId);
          this.newPickup.origin.address = tempAddress;
          this.fnApiGetCarriers(this.newPickup.origin.address.country.code);
        }
        this.newPickup.shipment.carrier = null;
      },

      fnChangeCarrier() {
        if (this.newPickup.shipment.carrier) {
          this.newPickup.shipment.pickup.date = this.$moment().add(1, 'days').format(this.CONST.DATE_FORMATS
            .YYYY_MM_DD);
          this.carrierPickup = this.carriersList.find(carrier => carrier.name == this.newPickup.shipment.carrier);
          this.fnGenerateHours();
        }
      },

      fnGenerateHours() {
        this.pickupsErrors.newPickup = null;
        this.availableStartHours = [];
        this.availableEndHours = [];
        let tempDate = this.$moment(this.newPickup.shipment.pickup.date).format();
        this.pickupDateString = this.$moment(tempDate).format(this.CONST.DATE_FORMATS.DD_MM_YYYY);

        if (this.carrierPickup.pickup.days.length > 0) {
          let today = this.newPickup.shipment.pickup.date == this.$moment().format(this.CONST.DATE_FORMATS.YYYY_MM_DD);
          let tempDay = this.$moment(tempDate).day();
          if (tempDay == 0) {
            tempDay = 6;
          } else {
            tempDay = tempDay - 1;
          }

          this.limits = this.carrierPickup.pickup.days[tempDay];

          if (this.limits.sameday === null) {
            return null;
          }

          var hourStart = this.limits.hour_start;
          let hourEnd = 0;

          if (today) {
            if ((this.limits.sameday == 0)) {
              return null;
            }
            hourEnd = this.limits.hour_limit;
            // var currentHour = Number(this.$moment().format('HH'));
            // if (currentHour < hourEnd) {
            //   hourStart = currentHour;
            // } else {
            //   hourStart = 24;
            // }
          } else {
            hourEnd = this.limits.hour_end;
          }

          if (this.limits.hour_span == 0) {
            this.limits.hour_span = 1;
          }
          for (let hour = hourStart; hour <= (hourEnd - this.limits.hour_span); hour++) {
            this.availableStartHours.push({
              label: ((hour < 10) ? ('0' + hour) : hour) + ':00',
              value: hour,
            })
          }

          if (this.availableStartHours.length) {
            this.newPickup.shipment.pickup.time_from = this.availableStartHours[0].value;
            this.fnChangeStartHour();
          }

        }
      },

      fnChangeStartHour() {
        this.availableEndHours = [];
        if (this.newPickup.shipment.pickup.time_from) {
          // let endHour = this.newPickup.shipment.pickup.time_from + this.limits.hour_span;
          let endHour = this.limits.hour_end
          this.availableEndHours.push({
            value: endHour,
            label: ((endHour < 10) ? ('0' + endHour) : endHour) + ':00',
          });
          this.newPickup.shipment.pickup.time_to = endHour;
        } else {
          this.newPickup.shipment.pickup.time_to = null;
        }
      },

    },
    mounted() {
      this.countriesList = this.fnApiGetCountries();
    },
    beforeDestroy() {
      this.resetState();
    }
  }
</script>